.scene-button {
  &_type {
    &_close {
      left: 0;
    }

    &_move {
      top: -75px;
      left: -50px;
    }

    &_delete {
      top: -75px;
      left: 50px;
    }

    &_copy {
      top: -75px;
    }
  }
}
