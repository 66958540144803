@use "sass:math";

$border-width: 2px;
$height-desktop: 40px;
$height-mobile: 30px;

.overview-panel {
  position: fixed !important;
  top: 20px;
  right: 10px;
  z-index: 10;
  width: 220px;
  background: var(--c-background-color);
  border: $border-width solid var(--c-text-color);
  border-radius: 15px;

  &::before,
  &::after {
    top: math.div($height-mobile - $border-width * 2, 2) !important;
  }

  &__section {
    &_type {
      &_metrics {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }

  &__metric {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    padding: 4px;
    line-height: 1.25;

    &:last-child:nth-child(odd) {
      width: 100%;
    }

    &-icon {
      width: 100%;
      height: 100%;
    }

    &-image {
      display: flex;
      align-items: center;
      width: 50px;
      height: 50px;
      color: var(--c-accent-color);

      &_active {
        color: var(--c-text-color);
      }

      & img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &-value {
      font-weight: 700;
      text-align: center;
    }

    &-label {
      color: var(--c-secondary-color);
      font-size: 12px;
      text-align: center;
    }

    &-button {
      display: inline-block;

      &-icon {
        position: relative;
        width: 15px;
        height: 15px;
        vertical-align: text-bottom;
        background-size: 15px 15px;
      }
    }
  }

  &__caveat {
    width: 100%;
    margin: 10px 0;
    padding-bottom: 10px;
    color: var(--c-secondary-color);
    text-align: center;
    border-bottom: 1px solid var(--c-disabled-color);
  }

  @media (min-width: 640px) {
    right: 20px;
    width: 280px;
    border-radius: 20px;

    &::before,
    &::after {
      top: math.div($height-desktop - $border-width * 2, 2) !important;
    }
  }

  &__container {
    overflow: hidden;
    border-radius: 15px;

    @media (min-width: 640px) {
      border-radius: 20px;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: $height-mobile - $border-width * 2;
    padding: 0 10px 0 0;
    font-size: 12px;
    background: none;
    border: none;
    border-bottom: 1px solid var(--c-disabled-color);
    transition: background-color 300ms;

    @media (min-width: 640px) {
      height: $height-desktop - $border-width * 2;
      font-size: 22px;
    }

    &:hover {
      background: var(--c-text-a10-color);
    }

    &-content {
      flex-grow: 1;
      font-weight: 300;
      font-family: 'Comfortaa', cursive;
    }
  }

  &__body {
    position: relative;
    max-height: 0;
    overflow: auto;
    opacity: 0;
    transition: opacity 400ms, max-height 450ms;

    &_open {
      opacity: 1;
    }
  }

  &__area,
  &__price {
    padding: 0 8px;
  }

  &__area + &__price {
    border-left: 1px solid var(--c-disabled-color);
  }

  &__content {
    padding: 0 10px 10px;

    @media (min-width: 640px) {
      padding: 0 20px 10px;
    }
  }
}
