.summary-details {
  position: sticky;
  top: 20px;
  width: 100%;
  max-width: 360px;
  padding: 0 20px;
  border: 2px solid var(--c-disabled-color);

  &__header {
    padding: 20px 0;
    font-size: 22px;
    text-align: center;
    border-bottom: 1px solid var(--c-disabled-color);
    user-select: text;
  }

  &__title {
    font-weight: 300;
    font-family: 'Comfortaa', cursive;
  }

  &__instance-info {
    color: var(--c-secondary-color);
    font-size: 16px;
  }

  &__instance-name {
    color: var(--c-text-color);
    font-size: 16px;
  }

  &__actions {
    padding: 10px 0 20px 0;

    &:empty {
      display: none;
    }
  }

  &__save-btn {
    color: var(--c-secondary-color);
    text-transform: lowercase;
    text-decoration: underline;
    background: none;
    border: none;
  }

  &__unsaved-mark {
    color: var(--c-accent-color);
  }
}
