@mixin xs {
  min-width: auto;
  min-height: auto;
  padding: 0 5px;
  font-size: 12px;
  border-width: 1px;
}

.button-v2 {
  $this: &;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  padding: 0 20px;
  overflow: hidden;
  text-align: center;
  border: 2px solid transparent;
  transition: background-color 300ms, color 300ms;

  &[disabled],
  &:disabled {
    pointer-events: none;
  }

  &_block {
    width: 100%;
  }

  &_bold {
    font-weight: 700;
  }

  &_regular {
    font-weight: 400;
  }

  &_size {
    &_xs {
      @include xs;

      &-rounded {
        @include xs;

        border-radius: 30px;
      }
    }

    &_md {
      min-height: 40px;

      &-rounded {
        min-height: 40px;
        border-radius: 20px;
      }

      &-circular {
        flex-shrink: 0;
        width: 40px;
        min-width: initial;
        min-height: 40px;
        padding: 0;
        border-radius: 50%;
      }
    }

    &_lg {
      min-height: 50px;
      font-size: 22px;

      &-rounded {
        min-height: 50px;
        font-size: 22px;
        border-radius: 25px;
      }

      &-circular {
        display: inline-flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        width: 50px;
        min-width: initial;
        min-height: 50px;
        padding: 0;
        border-radius: 50%;
      }
    }
  }

  &_color {
    &_main {
      color: var(--c-background-color);
      background: var(--c-main-color);

      &[disabled],
      &:disabled {
        background: var(--c-disabled-color) !important;
      }

      &:hover {
        background: var(--c-main-hover-color);
      }

      &-outline {
        color: var(--c-main-color);
        background: var(--c-background-color);
        border-color: var(--c-main-color);

        &[disabled],
        &:disabled {
          color: var(--c-disabled-color);
          background: var(--c-background-color) !important;
          border-color: var(--c-disabled-color);
        }

        &:hover {
          background: var(--c-main-a10-color);
        }
      }

      &-inverse {
        color: var(--c-main-color);
        background: var(--c-background-color);
        border-color: var(--c-main-color);

        &[disabled],
        &:disabled {
          color: var(--c-disabled-color);
          border-color: var(--c-disabled-color);
        }

        &:hover {
          color: var(--c-background-color);
          background: var(--c-main-color);
        }
      }
    }

    &_text-borderless {
      color: var(--c-text-color);
      background: var(--c-background-color);

      &[disabled],
      &:disabled {
        color: var(--c-disabled-color);
      }

      &:hover {
        background: var(--c-text-a10-color);
      }
    }

    &_text {
      color: var(--c-text-color);
      background: var(--c-background-color);
      border-color: var(--c-text-color);

      &[disabled],
      &:disabled {
        color: var(--c-disabled-color);
        border-color: var(--c-disabled-color);
      }

      &:hover {
        background: var(--c-text-a10-color);
      }
    }

    &_link {
      color: var(--c-secondary-color);
      text-decoration: underline;
      background: transparent;
      border: 0;

      &[disabled],
      &:disabled {
        color: var(--c-disabled-color);
        border-color: var(--c-disabled-color);
      }

      &:hover {
        color: var(--c-secondary-hover-color);
      }
    }

    &_secondary {
      color: var(--c-secondary-color);
      background: var(--c-background-color);
      border-color: var(--c-secondary-color);

      &[disabled],
      &:disabled {
        color: var(--c-disabled-color);
        border-color: var(--c-disabled-color);
      }

      &:hover {
        background: var(--c-text-a10-color);
      }
    }

    &_secondary-borderless {
      color: var(--c-secondary-color);
      background: var(--c-background-color);

      &[disabled],
      &:disabled {
        color: var(--c-disabled-color);
      }

      &:hover {
        background: var(--c-text-a10-color);
      }
    }

    &_secondary-hovered {
      color: var(--c-background-color);
      background: var(--c-secondary-hover-color);
    }

    &_accent {
      color: var(--c-background-color);
      background: var(--c-accent-color);

      &[disabled],
      &:disabled {
        background-color: var(--c-disabled-color);
      }

      &:hover {
        background: var(--c-accent-hover-color);
      }

      &-outline {
        color: var(--c-accent-color);
        background: var(--c-background-color);
        border-color: var(--c-accent-color);

        &[disabled],
        &:disabled {
          color: var(--c-disabled-color);
          border-color: var(--c-disabled-color);
        }

        &:hover {
          background: var(--c-accent-a10-color);
        }
      }
    }

    &_icon {
      width: 40px;
      height: 40px;
      padding: 0;
      color: var(--c-text-color);
      background: var(--c-background-color);
      transition: color 200ms;

      &[disabled],
      &:disabled {
        color: var(--c-disabled-color);
      }

      /* Each icon button must work their hover states out by itself */
    }
  }
}
