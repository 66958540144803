$background-transition: background-color 200ms;

.menu {
  position: fixed;
  z-index: 300;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media (min-width: 640px) {
    background: rgba(255, 255, 255, 0.7);
  }

  &__body {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    overflow: auto;
    background: var(--c-background-color);

    @media (min-width: 640px) {
      max-width: 360px;
      border-right: 1px solid var(--c-disabled-color);
    }
  }

  &__header {
    position: sticky;
    top: 0;
    z-index: 10;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    margin: 0 0 10px;
    background: var(--c-background-color);

    &::after {
      position: absolute;
      right: 0;
      bottom: -20px;
      left: 0;
      display: block;
      height: 20px;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
      content: '';
    }
  }

  &__close {
    border: none;
  }

  &__logo {
    display: block;
    height: 60px;
  }

  &__section {
    position: relative;

    /* To disable margin collapse */
    display: flex;
    flex-direction: column;
    padding: 10px;

    & > * {
      /* All direct children have a 10px top and bottom margin unless overridden */
      margin: 10px 0;
    }

    &:empty {
      padding: 0;
    }

    &:not(:first-child) {
      &::before {
        position: absolute;
        top: 0;
        right: 14px;
        left: 14px;
        display: block;
        height: 1px;
        background: var(--c-disabled-color);
        content: '';
      }
    }

    &_type {
      &_footer {
        position: relative; /* To enable z-index */
        z-index: -1; /* To enable dropdowns to go on top of it */
        flex-shrink: 0;
        margin-top: auto;
        padding: 0;
      }

      &_progress {
        position: relative;
        min-height: 200px;
      }
    }
  }

  &__button {
    &_center {
      display: inline;
      margin: 0 auto;
    }
  }

  &__heading {
    margin-bottom: 30px;
    font-weight: 300;
    font-size: 22px;
    font-family: 'Comfortaa', cursive;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 60px;
    margin: 0 -10px;
    padding: 10px 14px;
    background: var(--c-background-color);
    border: none;
    cursor: pointer;
    transition: $background-transition;

    &_disabled {
      pointer-events: none;
    }

    &_type {
      &_profile,
      &_logout {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }

    &-name {
      &_type {
        &_switcher,
        &_switcher-dropdown {
          position: relative;
          font-size: 22px;
        }

        &_switcher {
          font-weight: 300;
          font-family: 'Comfortaa', cursive;
        }

        &_switcher-dropdown {
          font-weight: 700;

          &::after {
            position: absolute;
            top: 50%;
            right: -20px;
            display: block;
            font-size: 18px;
            transform: translateY(-50%);
            content: '\25BE';
          }
        }

        &_unsaved,
        &_deprecated {
          font-weight: 700;
        }
      }
    }

    &-description {
      color: var(--c-secondary-color);
      font-size: 12px;
      text-align: left;

      &_type {
        &_centered {
          text-align: center;
        }
      }

      &_divider {
        padding: 10px 0 0;
        border-top: 1px solid  var(--c-disabled-color);
      }
    }

    &:hover {
      background: var(--c-text-a10-color);
    }
  }

  &__switcher-list {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 8;
    background: var(--c-background-color);
    border: 4px solid var(--c-disabled-color);
    visibility: hidden;
    opacity: 0;
    transition: opacity 200ms;

    &_open {
      visibility: visible;
      opacity: 1;
    }

    &-item {
      display: block;
      width: 100%;
      height: 40px;
      padding: 0 12px;
      line-height: 40px;
      text-align: left;
      background: var(--c-background-color);
      border: none;
      transition: $background-transition;

      &:hover {
        background: var(--c-text-a10-color);
      }
    }
  }

  &__submit-button {
    margin-top: 20px;
  }

  &__animation {
    &-enter,
    &-appear {
      & .menu__body {
        transform: translateX(-20%);
      }

      opacity: 0;

      &-active,
      &-done {
        & .menu__body {
          transform: translateX(0);
          transition: 0.2s transform;
        }

        opacity: 1;
        transition: 0.2s opacity;
      }
    }

    &-exit {
      & .menu__body {
        transform: translateX(0);
      }

      opacity: 1;

      &-active,
      &-done {
        & .menu__body {
          transform: translateX(-20%);
          transition: 0.2s transform;
        }

        opacity: 0;
        transition: 0.2s opacity;
      }
    }
  }

  &__animation-children {
    &-enter {
      transform: translateY(20px);
      opacity: 0;

      &-active,
      &-done {
        transform: translateY(0);
        opacity: 1;
        transition: 0.15s all ease;
      }
    }

    &-exit {
      transform: translateY(0);
      opacity: 1;

      &-active,
      &-done {
        transform: translateY(-20px);
        opacity: 0;
        transition: 0.15s all ease;
      }
    }
  }

  &__unsaved-indicator,
  &__deprecation-indicator {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }

  &__deprecation-indicator {
    width: 8px;
    height: 8px;
    background: var(--c-accent-color);
    border-radius: 50%;
  }
}

.consent {
  &__links {
    margin: 20px 0;
    font-weight: 700;
    font-size: 16px;
  }

  &__link {
    color: var(--c-main-color);
    text-decoration: underline;
  }
}

.form {
  /* To disable margin collapse */
  display: flex;
  flex-direction: column;

  & > * {
    margin: 10px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__footer {
    display: block;
    margin: 16px 0;
    color: var(--c-secondary-color);
    font-size: 12px;
    text-align: center;
  }
}
