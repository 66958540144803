.page-header {
  position: relative;
  width: 100%;
  padding: 90px 0 40px;

  &__logo {
    position: absolute;
    top: 5px;
    right: 0;
    height: 60px;
  }

  &__title {
    font-weight: 900;
    font-size: 42px;
    font-family: 'Rubik', sans-serif;
    line-height: 1.2;
    user-select: text;

    @media (min-width: 768px) {
      font-size: 52px;
    }
  }
}
