.content-title {
  margin: 60px 0 40px;
  font-weight: 300;
  font-size: 22px;
  font-family: 'Comfortaa', cursive;
  text-align: center;
  user-select: text;

  @media (min-width: 768px) {
    margin: 80px 0 40px;
  }
}
